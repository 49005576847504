import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import { loadReCaptcha } from 'react-recaptcha-v3';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';

import LoadingScreen from 'components/LoadingScreen';
import Header from 'components/Header/Header';
import BookAppointment from 'components/BookAppointment';
import Footer from 'components/Footer/Footer';

import { recaptcha } from 'src/config/app.conf';
import Popup from './components/Popup';
// import Popup from './components/Popup';

const App = () => {
  useEffect(() => {
    prerenderStyles();
    !window.__PRERENDERING && loadReCaptcha(recaptcha);
  }, []);

  const generateRoutes = () => {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  };

  return (
    <Route
      render={({ location }) => (
        <Root>
          <LoadingScreen />
          <Header />
          <Popup />
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={500} classNames="fade">
              <Switch location={location}>{generateRoutes()}</Switch>
            </CSSTransition>
          </TransitionGroup>
          <BookAppointment />
          <Footer />
        </Root>
      )}
    />
  );
};

const Root = styled.div`
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
  .book {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
`;

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
