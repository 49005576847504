import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useScrollDown } from 'bam-storybook';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Nav = (props) => {
  const { t, locale, getLocaleURL } = useIntl();
  const { isOpen, setIsOpen } = props;

  const location = useLocation();

  const hasScrolledDown = useScrollDown(300);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Root hasScrolledDown={hasScrolledDown}>
      <Logo to={`${getLocaleURL()}`}>
        <img
          src={require('src/assets/images/_global/logos/logo_full_white.svg')}
          alt="Riviera logo"
        />
      </Logo>
      <Hamburger isOpen={isOpen} onClick={handleMenu}>
        <span />
        <span />
        <span />
      </Hamburger>

      <Language>
        <LangLink to="/" active={locale === 'en'}>
          EN
        </LangLink>
        <LangLink to="/tc" active={locale === 'tc'}>
          中文
        </LangLink>
        <LangLink to="/ko" active={locale === 'ko'}>
          한국어
        </LangLink>
      </Language>

      <Container>
        <Links>
          {t['header'].routes.map((route, i) => (
            <StyledLink
              active={location.pathname.includes(route.path)}
              key={i}
              to={`${getLocaleURL()}${route.path}`}
              className="main_menu"
            >
              {route.text}
            </StyledLink>
          ))}
        </Links>
        <StyledButton
          to={`${getLocaleURL()}/register`}
          // href="https://app.acuityscheduling.com/schedule.php?owner=20816987&location=4700%20Imperial%20Street%2C%20Burnaby%2C%20BC"
          text={t['register']}
          gold
          mobile
        />
        <StyledButton
          to={`${getLocaleURL()}/register`}
          // href="https://app.acuityscheduling.com/schedule.php?owner=20816987&location=4700%20Imperial%20Street%2C%20Burnaby%2C%20BC"
          text={t['registerNow']}
          gold
        />
      </Container>
    </Root>
  );
};

export default Nav;

Nav.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.navy};
  transform: ${(props) =>
    props.hasScrolledDown ? 'translateY(-100%)' : 'translateY(0)'};
  opacity: ${(props) => (props.hasScrolledDown ? '0' : '1')};
  transition: all 0.4s ease;
  z-index: 2;
  ${vw('height', 52, 80, 120)}
  ${vw('padding-right', 16, 25, 50)}
  ${vw('padding-left', 16, 25, 50)}
`;

const Logo = styled(Link)`
  img {
    ${vw('width', 100, 140, 177)}
  }
`;

const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 20)}
  ${vw('height', 14)}

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: white;
    position: absolute;
    transition: all 0.4s ease;

    &:nth-of-type(1) {
      top: ${(props) => (props.isOpen ? '50%' : '0')};
      left: 0;
      transform: ${(props) =>
        props.isOpen ? 'translateY(-50%) rotate(45deg)' : ''};
    }

    &:nth-of-type(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      opacity: ${(props) => (props.isOpen ? '0' : '1')};
    }

    &:nth-of-type(3) {
      top: ${(props) => (props.isOpen ? '50%' : 'calc(100% - 2px)')};
      left: 0;
      transform: ${(props) =>
        props.isOpen ? 'translateY(-50%) rotate(-45deg)' : ''};
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Language = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    position: absolute;
    ${vw('top', 20)}
    ${vw('right', 50)}
  }
`;

const LangLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.family};
  font-weight: 400;
  color: white;
  border-bottom: ${(props) =>
    props.active ? '2px solid white' : '2px solid transparent'};
  padding-bottom: 1px;
  transition: all 0.4s ease;
  ${vw('font-size', 13)}
  ${vw('line-height', 20)}
  ${vw('letter-spacing', 1)}
  &:nth-of-type(2) {
    ${vw('margin-right', 24)}
    ${vw('margin-left', 24)}
  }
  &:hover {
    border-bottom: 2px solid white;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  ${vw('margin-top', 0, 0, 20)}

  ${(props) =>
    props.mobile
      ? vw('display', 'block', ' none')
      : vw('display', 'none', 'block')}
`;

const Links = styled.div`
  display: none;
  ${vw('margin-top', 0, 0, 20)}
  @media ${media.desktop} {
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => (props.active ? props.theme.color.gold : 'white')};
  text-decoration: none;
  ${vw('margin-right', 40)}
  @media ${media.desktop} {
    &:hover {
      color: ${({ theme }) => theme.color.gold};
    }
  }
`;
