import React from 'react';

export default {
  register: 'REGISTER',
  registerNow: 'Register Now',
  bookAppointment: <>BOOK A TOUR</>,
  header: {
    routes: [
      {
        path: '/floorplans',
        text: 'Floorplans',
      },
      {
        path: '/interiors',
        text: 'Interiors',
      },
      {
        path: '/amenities',
        text: 'Amenities',
      },
      {
        path: '/neighbourhood',
        text: 'Neighbourhood',
      },
      {
        path: '/gallery',
        text: 'Gallery',
      },
      {
        path: '/developer',
        text: 'Developer',
      },
    ],
  },
  footer: {
    column1: {
      title: 'Contact Us',
      buttonLabel: 'Register Now',
    },
    column2: {
      title: 'Presentation Centre',
      text: (
        <>
          Open 12-5 pm <br />
          Saturday - Wednesday
          <br />
          <br />
          4700 Imperial Street, <br />
          Burnaby, BC
        </>
      ),
    },
    column3: {
      title: 'Site Location',
    },
    marketing: 'marketing in association with',
    disclaimer:
      '*Offers valid until February 13, 2025. The 3% plus up to $9,888 savings will be shown as credit on statement of adjustments upon completion. Ask a sales representative for more details. Limited time, prices, incentives, and commissions are subject to change without notice. The information contained in this promotional material is for illustration purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The quality residences at Riviera are developed by LM Riviera Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. Offers can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: 'Confidence. Quality. Value',
      text: 'At Ledingham McAllister, we are dedicated to creating quality homes in superior locations and providing our customers with long-term value. We are committed to the highest standards in everything we do. Our past was built on it, our future depends on it.',
      privacy: 'Privacy Policy',
    },
  },
  home: {
    header: (
      <>
        Mid-Century <br className="mobile" /> Elegance,{' '}
        <br className="tablet" /> Luxurious <br className="mobile" /> Modern
        Living
      </>
    ),
    hero: {
      title:
        'Defined by an iconic integration of world-class craftsmanship and sophisticated Mid-Century aesthetics, Riviera reflects the very best of elevated urban living in the heart of Metrotown.',
      text: 'The striking 38 storey collection of spacious one-bedroom and den to three-bedroom homes are meticulously designed with the finest finishes and extraordinary amenities to amplify your lifestyle. This is living. This is luxury. This is Riviera on the Park.',
      button: 'View Floorplans',
    },
    content1: {
      title: 'Embodiment of Elegance',
      text: 'Step inside your home and be transported. Every finish and fixture has been carefully defined to craft homes of serene sophistication and elegance. Spacious, well-planned layouts are equally suited to flawless family living and effortlessly hosting special occasions.',
      button: 'Explore Interiors',
    },
    content2: {
      title: 'Gather in Style',
      text: 'Your living space isn’t limited to your home. Just an elevator ride away, an entire floor of hospitality-inspired amenities awaits, expanding your ability to live your most fulfilling life—from wellness to entertainment and everything in between.',
      button: 'Explore Amenities',
    },
    content3: {
      title: 'A World of Opportunity',
      text: 'Situated at the crossroads of serenity and elevated urban living, Riviera rises above lovely and lush Lobley Park, steps from one of the preeminent shopping, dining, and entertainment destinations in the country.',
      button: 'Explore the Neighbourhood',
    },
  },
  developer: {
    hero: {
      title: 'CONFIDENCE.  QUALITY.  VALUE',
      body: 'At Ledingham McAllister we are dedicated to smart development by creating superior new home communities in great locations. For over a century it has been our commitment to exacting standards of design, environmental leadership, engineering, and construction that ensures homes of enduring quality and value. More than 20,000 homes later, we can proudly state that our past was built on it, and our future depends on it.',
    },
    companies: {
      ibi: {
        description:
          'IBI Group is now proudly a part of Arcadis. Arcadis Group is a technology-driven design firm, providing architecture, engineering, planning, systems, and technology services to their clients for nearly 50 years. With more than 2,700 professionals located in over 60 offices around the world, they create responsive, resilient spaces and smart urban environments by bringing the established capabilities from their Intelligence practice into their core Buildings and Infrastructure businesses. From high-rises to hospitals, and transit systems to schools, Arcadis Group shapes the way people live, work, move, learn, and heal in the cities of tomorrow.',
      },
      pwl: {
        description:
          'For 40 years, PWL Partnership’s team of landscape architects, urban designers, and planners have built a reputation for excellence across Canada, the USA, and Asia. Their office has five Partners, four Associates and a team of 30 landscape architects and designers. Based in Vancouver, PWL works hard to establish meaningful and lasting relationships with our client groups, project teams, and local stakeholders. The firm’s experience encompasses complex waterfronts, greenways, transit corridors, urban plazas, streetscapes, neighbourhood parks and parklets.',
      },
      themill: {
        description:
          'The Mill Design Group Inc. was founded in 2011 by interior designer, Janine Wilson.  Based in beautiful North Vancouver, The Mill Design Group Inc. specializes in Multi-Family residential design. With over 18 years of experience in the interior design industry, Janine and her amazing team work with developers to create interiors and home finishes that reflect the best of a neighbourhood while embodying the future buyer’s esthetics and values.  The Mill’s design belief is that interior finishes should be timeless and easy to manage, without sacrificing style and comfort.',
      },
    },
    projects: [
      {
        image: require('src/assets/images/developer/projects/01_escala-burnaby.jpg'),
        text: 'Escala, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/02_aviara-burnaby.jpg'),
        text: 'Aviara, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/03_reflections-burnaby.jpg'),
        text: 'Reflections, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/04_waterscapes-kelowna.jpg'),
        text: 'Waterscapes, Kelowna',
      },
      {
        image: require('src/assets/images/developer/projects/05_highpoint-coquitlam.jpg'),
        text: 'Highpoint, Coquitlam',
      },
      {
        image: require('src/assets/images/developer/projects/06_icon-burnaby.jpg'),
        text: 'Icon, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/07_sydney-coquitlam.jpg'),
        text: 'Sydney, Coquitlam',
      },
      {
        image: require('src/assets/images/developer/projects/08_azure-burnaby.jpg'),
        text: 'Azure, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/09_northgate_village-burnaby.jpg'),
        text: 'Northgate Village, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/10_perspectives-burnaby.jpg'),
        text: 'Perspectives, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/11_saltaire-white_rock.jpg'),
        text: 'Saltaire, White Rock',
      },
      {
        image: require('src/assets/images/developer/projects/12_dominion-new_westminster.jpg'),
        text: 'Dominion, New Westminster',
      },
    ],
  },
  form: {
    title: 'Register Now',
    firstname: 'First Name *',
    lastname: 'Last Name *',
    email: 'Email Address *',
    phone: 'Phone Number *',
    zip: 'Postal/Zip Code *',
    select: 'Select One...',
    howHeard: 'How did you hear about us? *',
    howHeardOptions: [
      {
        label: 'Chinese Online Ads',
        value: 'Chinese Online Ads',
      },
      {
        label: 'Chinese Print Ads',
        value: 'Chinese Print Ads',
      },
      {
        label: 'Community Paper',
        value: 'Community Paper',
      },
      {
        label: 'VanChosun',
        value: 'VanChosun',
      },
      {
        label: 'Korean Real Estate Weekly',
        value: 'Korean Real Estate Weekly',
      },
      {
        label: 'Facebook / Instagram',
        value: 'Facebook / Instagram',
      },
      {
        label: 'Google Search',
        value: 'Google Search',
      },
      {
        label: 'Ledmac Email',
        value: 'Ledmac Email',
      },
      {
        label: 'Ledmac Website',
        value: 'Ledmac Website',
      },
      {
        label: 'Mail Piece',
        value: 'Mail Piece',
      },
      {
        label: 'MLS.ca / Realtor.ca',
        value: 'MLS.ca / Realtor.ca',
      },
      {
        label: 'Online Ads',
        value: 'Online Ads',
      },
      {
        label: 'Outdoor Advertising',
        value: 'Outdoor Advertising',
      },
      {
        label: 'Realtor',
        value: 'Realtor',
      },
      {
        label: 'Signage',
        value: 'Signage',
      },
      {
        label: 'Skytrain',
        value: 'Skytrain',
      },
      {
        label: 'Social Media',
        value: 'Social Media',
      },
      {
        label: 'The Province',
        value: 'The Province',
      },
      {
        label: 'Vancouver Sun',
        value: 'Vancouver Sun',
      },
      {
        label: 'WeChat',
        value: 'WeChat',
      },
      {
        label: 'Word of Mouth',
        value: 'Word of Mouth',
      },
    ],
    whatInquire: 'What are you inquiring about?',
    whatInquireOptions: [
      {
        label: 'One bedroom',
        value: 'One bedroom',
      },
      {
        label: 'Two bedroom',
        value: 'Two bedroom',
      },
      {
        label: 'Three bedroom',
        value: 'Three bedroom',
      },
      {
        label: 'Penthouses',
        value: 'Penthouses',
      },
    ],
    realtor: 'Are you a realtor?*',
    workingWithRealtor: 'Are you working with a realtor?',
    yes: 'Yes',
    no: 'No',
    ifYesRealtor: `If you are a realtor or working with a realtor, please indicate your realtor's name or brokerage:`,
    disclaimer:
      'By checking this box, I express my consent to receive future e-communications from Ledingham McAllister, including information about upcoming developments, early access and special offers.*',
    submit: 'submit your registration',
    required: '* Required field',
  },
  gallery: {
    hero: {
      title: 'Spectacular Spaces, Inspired Surroundings',
      smallStrapline:
        'Bright open floor plans to maximize living space, full-size luxury appliances',
    },
    interiors: {
      title: 'Interiors',
      images: [
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/living-space_light.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/living-space_light.jpg'),
        //   alt: 'Kitchen | Opal Scheme',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/living-space_dark.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/living-space_dark.jpg'),
        //   alt: 'Kitchen | Onyx Scheme',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/ensuite_light.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite_light.jpg'),
        //   alt: 'Ensuite | Opal Scheme',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/ensuite_dark.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite_dark.jpg'),
        //   alt: 'Ensuite | Onyx Scheme',
        // },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-12.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-12.jpg'),
          alt: '1 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-05.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-05.jpg'),
          alt: '1 Bed | Integrated Refrigerator',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-11.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-11.jpg'),
          alt: '1 Bed | Kitchen & Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-13.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-13.jpg'),
          alt: '1 Bed | Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-02.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-02.jpg'),
          alt: '1 Bed | Den',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-01.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-01.jpg'),
          alt: '1 Bed | Main Bathroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-04.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-04.jpg'),
          alt: '1 Bed | Main Bedroom',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-16.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-16.jpg'),
          alt: '2 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-18.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-18.jpg'),
          alt: '2 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-15.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-15.jpg'),
          alt: '2 Bed | Dining & Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-17.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-17.jpg'),
          alt: '2 Bed | Kitchen & Dining',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-14.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-14.jpg'),
          alt: '2 Bed | Kitchen & Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-19.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-19.jpg'),
          alt: '2 Bed | Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-03.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-03.jpg'),
          alt: '2 Bed | Main Bathroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-08.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-08.jpg'),
          alt: '2 Bed | Main Bedroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-06.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-06.jpg'),
          alt: '2 Bed | Main Bedroom Closet',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-07.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-07.jpg'),
          alt: '2 Bed | Ensuite',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-09.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-09.jpg'),
          alt: '2 Bed | 2nd Bedroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-10.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-10.jpg'),
          alt: '2 Bed | Balcony',
        },
      ],
    },
    neighbourhood: {
      title: 'Neighbourhood',
      images: [
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_01.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_01_thumb.jpg'),
          alt: 'Metrotown',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_02.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_02_thumb.jpg'),
          alt: 'Metropolis',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_03.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_03_thumb.jpg'),
          alt: 'SkyTrain',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_04.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_04_thumb.jpg'),
          alt: 'Central Park',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_05.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_05_thumb.jpg'),
          alt: 'Deer Lake Park',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_06.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_06_thumb.jpg'),
          alt: 'Local Grocery Store',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_07.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_07_thumb.jpg'),
          alt: 'Upscale Restaurant',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_08.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_08_thumb.jpg'),
          alt: 'Luxury Shopping',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_09.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_09_thumb.jpg'),
          alt: 'Simon Fraser University',
        },
      ],
    },
    building: {
      title: 'Building',
      images: [
        {
          src: require('src/assets/images/gallery/building/lightbox/exterior.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/exterior.jpg'),
          alt: 'Auto Court Entrance',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/entrance.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/entrance.jpg'),
          alt: 'Entrance',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/terrace.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/terrace.jpg'),
          alt: 'Terrace',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/lobby-concierge.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/lobby-concierge.jpg'),
          alt: 'Lobby Concierge',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/lobby-fireplace.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/lobby-fireplace.jpg'),
          alt: 'Lobby Lounge',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/amenity_ping-pong.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/amenity_ping-pong.jpg'),
          alt: 'Social Lounge',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/amenity_kitchen.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/amenity_kitchen.jpg'),
          alt: "Chef's Kitchen and Dining",
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/rooftop.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/rooftop.jpg'),
          alt: 'Terrace',
        },
      ],
    },
  },
  interiors: {
    hero: {
      title: 'The Embodiment of Elegance',
      fullStrapline:
        'Step inside your home and be transported. Spacious, well-planned layouts are equally suited to flawless family living and effortlessly hosting special occasions. Expansive windows invite natural light inside, casting a vibrant glow throughout your home. The intuitive, open, and airy flow is enhanced by generous balconies to embrace authentic indoor-outdoor living. ',
    },
    opal: 'Opal',
    onyx: 'Onyx',
    opalOnyx: {
      content2: {
        title: 'Tailored with Taste',
        body: 'Every finish and fixture has been carefully defined to craft homes of serene sophistication and elegance. Your choice of two shade and texture palettes curated by The Mill Design Group—warm and vibrant Opal, or cool and elegant Onyx—enable you to reflect and refine your personal sense of style.',
      },
    },
    features: {
      title: 'Intelligent Design',
      column1: [
        'A spacious island with rich stone waterfall edge offers expansive space for prep or platters',
        'True-height cabinetry, including a pantry, are paired with sleek quartz composite counters and a seamless Carrara-inspired backsplash',
        'Cook and entertain with full-sized appliances, including a chef-grade Bosch, five-burner gas cooktop and integrated Fisher & Paykel French-door refrigerator',
      ],
      column2: [
        'Full-sized, front-loading, energy-efficient washer and dryer',
        'Spa-inspired bathrooms featuring custom-crafted mirrored medicine cabinets and a stunning floating vanity with soft underlighting',
        'A frameless glass shower or spacious soaker tub amplifies the atmosphere of rest and relaxation.',
      ],
      download: 'Download Features Sheet',
    },
  },
  amenities: {
    hero: {
      title: 'An Unforgettable First Impression',
      fullStrapline:
        'Designed by award-winning IBI Architects, Riviera inspires at first sight. The grand entrance sparkles with an illuminated water feature and distinctive gold lattice metalwork. A convenient hotel-inspired auto court ensures an effortless arrival or pick-ups. Grand oversized chandeliers channel the Mid-Century prestige while the concierge awaits to offer uncompromising assistance.',
    },
    content1: {
      title: 'Gather in Style',
      body: 'Just an elevator ride away, an entire floor of hospitality-inspired amenities awaits, expanding your ability to live your most fulfilling life. A fully-equipped Fitness Centre lets you exercise at your convenience, a furnished guest suite enables friends or family to remain close by over the holidays, while the Riviera Social Lounge has all the accoutrements of a private club.',
    },
    content2: {
      title: 'Under the Sun and Stars',
      body: 'Just outside, a beautifully composed and lushly landscaped outdoor terrace awaits. Fire tables create a flickering warmth that adds to the atmosphere of easygoing elegance, while barbecues and smartly designed seating and dining space provide ample areas for all manner of events.',
    },
    levels: {
      title: 'Come Home to Elevated Living',
      button1: 'Level 1',
      button2: 'Level 7',
    },
  },
  neighbourhood: {
    hero: {
      title: 'A World of Opportunity',
      fullStrapline:
        'Burnaby is home to every urban experience imaginable, including diverse restaurants and bars, specialty shops, clubs, pristine parks, playgrounds and more. From shopping to dancing, cafés to craft cocktails, it’s all right outside your door.',
    },
    content1: {
      title: 'Live More in Metrotown',
      text: 'Metropolis at Metrotown is a downtown destination you don’t have to travel for—the entrance is just minutes away on foot, offering unmatched access to over 300 shops and services. It’s an extravagant entertainment centre for the entire family, seven days a week.',
    },
    content2: {
      title: 'Go Everywhere with Ease',
      text: 'Walk to pick up groceries, cycle to Deer Lake, take transit to work—the level of convenience is unsurpassed. With ample transit options and two SkyTrain stations less than 5 minutes away, you can easily head to Downtown Vancouver, YVR Airport, and nearby schools such as Simon Fraser University and BCIT.',
    },
    select: 'Select a Category',
    map: [
      {
        title: 'Restaurants',
        list: [
          {
            name: 'Trattoria',
            top: '19.74%',
            left: '46.19%',
          },
          {
            name: 'Earls',
            top: '30.99%',
            left: '48.77%',
          },
          {
            name: 'Cactus Club',
            top: '37.82%',
            left: '47.42%',
          },
          {
            name: 'Chipotle',
            top: '47.97%',
            left: '55.89%',
          },
          {
            name: 'Mon Paris Patisserie',
            top: '44.09%',
            left: '40.04%',
          },
          {
            name: 'Sushi Garden',
            top: '24.72%',
            left: '52.70%',
          },
          {
            name: 'Marutama Ramen',
            top: '51.84%',
            left: '81.69%',
          },
          {
            name: "Nando's",
            top: '45.75%',
            left: '64.61%',
          },
          {
            name: 'Starbucks',
            top: '33.39%',
            left: '64.98%',
          },
          {
            name: 'La Forêt',
            top: '69.00%',
            left: '64.12%',
          },
          {
            name: 'Fondway Cafe',
            top: '55.53%',
            left: '50.36%',
          },
          {
            name: 'Jenjudan',
            top: '11.99%',
            left: '2.45%',
          },
          {
            name: 'Boiling Point',
            top: '56.27%',
            left: '87.46%',
          },
          {
            name: 'ZUBU',
            top: '52.58%',
            left: '56.26%',
          },
          {
            name: 'Bella Gelateria',
            top: '44.83%',
            left: '58.35%',
          },
          {
            name: 'The Dolar Shop',
            top: '35.05%',
            left: '45.08%',
          },
        ],
      },
      {
        title: 'Recreation',
        list: [
          {
            name: 'Bonsor Recreation Complex',
            top: '55.53%',
            left: '62.89%',
          },
          {
            name: 'Cineplex Cinemas Metropolis',
            top: '35.97%',
            left: '60.44%',
          },
          {
            name: 'Central Park',
            top: '39.66%',
            left: '15.23%',
          },
          {
            name: 'Central Park Public Tennis Courts',
            top: '32.28%',
            left: '22.97%',
          },
          {
            name: 'Central Park Pitch + Putt',
            top: '61.99%',
            left: '14.74%',
          },
          {
            name: 'Bonsor Park',
            top: '60.88%',
            left: '65.35%',
          },
          {
            name: 'Kinnee Park',
            top: '49.44%',
            left: '27.88%',
          },
          {
            name: 'Maywood Park',
            top: '58.30%',
            left: '34.27%',
          },
          {
            name: 'GoodLife Fitness',
            top: '20.84%',
            left: '47.54%',
          },
          {
            name: 'Fitness World',
            top: '57.38%',
            left: '89.06%',
          },
          {
            name: 'Anytime Fitness',
            top: '84.68%',
            left: '81.69%',
          },
          {
            name: 'David Gray Off-Leash Park',
            top: '84.68%',
            left: '35.50%',
          },
        ],
      },
      {
        title: 'Shopping & Services',
        list: [
          {
            name: 'Metropolis at Metrotown',
            top: '37.82%',
            left: '54.66%',
          },
          {
            name: 'Crystal Mall',
            top: '26.56%',
            left: '44.34%',
          },
          {
            name: "Hudson's Bay",
            top: '43.91%',
            left: '66.58%',
          },
          {
            name: 'ICBC',
            top: '45.94%',
            left: '62.89%',
          },
          {
            name: 'Pharmasave',
            top: '43.91%',
            left: '38.94%',
          },
          {
            name: 'PriceSmart Foods',
            top: '29.33%',
            left: '50.73%',
          },
          {
            name: 'RBC',
            top: '19.37%',
            left: '37.71%',
          },
          {
            name: 'Real Canadian Superstore',
            top: '33.39%',
            left: '52.82%',
          },
          {
            name: 'Walmart',
            top: '46.67%',
            left: '53.80%',
          },
          {
            name: 'Save-On-Foods',
            top: '14.94%',
            left: '44.34%',
          },
          {
            name: 'Rexall',
            top: '40.22%',
            left: '45.08%',
          },
          {
            name: 'Scotiabank',
            top: '13.09%',
            left: '36.48%',
          },
          {
            name: 'Shoppers Drug Mart',
            top: '32.65%',
            left: '64.12%',
          },
          {
            name: 'T&T Supermarket',
            top: '44.46%',
            left: '50.98%',
          },
          {
            name: 'TD Bank',
            top: '27.30%',
            left: '48.28%',
          },
          {
            name: 'HSBC',
            top: '23.06%',
            left: '45.82%',
          },
          {
            name: 'Vancity',
            top: '37.82%',
            left: '71.74%',
          },
          {
            name: 'Hilton Burnaby',
            top: '30.25%',
            left: '45.82%',
          },
          {
            name: 'Best Buy',
            top: '35.23%',
            left: '45.08%',
          },
          {
            name: 'BC Liquor Store',
            top: '13.09%',
            left: '41.40%',
          },
          {
            name: 'London Drugs',
            top: '40.77%',
            left: '69.28%',
          },
          {
            name: 'Burnaby Public Library',
            top: '34.50%',
            left: '40.66%',
          },
          {
            name: 'WeWork',
            top: '33.39%',
            left: '48.28%',
          },
        ],
      },
      {
        title: 'Schools',
        list: [
          {
            name: 'Marlborough Elementary',
            top: '28.41%',
            left: '72.60%',
          },
          {
            name: 'Maywood Community School',
            top: '61.25%',
            left: '54.66%',
          },
          {
            name: 'Burnaby South Secondary',
            top: '91.51%',
            left: '95.82%',
          },
          {
            name: 'South Slope Elementary',
            top: '84.68%',
            left: '42.50%',
          },
          {
            name: 'Wonderworld Montessori Academy',
            top: '45.20%',
            left: '41.03%',
          },
          {
            name: 'CEFA Early Learning',
            top: '4.98%',
            left: '19.16%',
          },
          {
            name: 'Suncrest Elementary',
            top: '91.51%',
            left: '19.16%',
          },
          {
            name: 'Nelson Elementary',
            top: '91.51%',
            left: '63.51%',
          },
        ],
      },
    ],
  },
  floorplans: {
    title: (
      <>
        A Collection of One-Bedroom <br /> and Den to Three-Bedroom Homes
      </>
    ),
    plan: 'Plan',
    type: 'Type',
    size: 'Approx. Sq Ft',
    download: 'Download Plan',
    disclaimer:
      'In a continuing effort to meet the challenge of product improvements, we reserve the right to modify or change dimensions, sizes, specifications, layouts, plan orientations and materials without notice. All patio, terrace and balcony sizes may vary. Windows, columns, privacy screens, demising walls, spandrel and guardrail details may vary depending upon the plan and floor level. The quality of homes at Riviera are built by LM Riviera Homes Limited Partnership. E.&O.E.',
    views: {
      title: (
        <>
          360º VIEW <br />
          OF A DREAM <br />
          COMMUNITY
        </>
      ),
      drag: 'Drag to pan the view',
    },
  },
  privacy: {
    title: 'Privacy Policy',
    body: (
      <>
        <p>
          This Privacy Policy has been prepared by Ledingham McAllister for
          their required site (herein known as “Riviera”, “we”, “us”, “our”) and
          sets out the way Ledingham McAllister collects, discloses, use, and
          otherwise manages personal information.
          <br />
          <br />
          The Privacy Policy also describes the privacy practices on the
          Ledingham McAllister required site website (the “website”, the “site”,
          or the “websites”) and through other interactions with consumers.
        </p>

        <h3>Collection and Use of Personal Information</h3>
        <p>
          <span>Guest Registry:</span> When you visit one of our sales centres
          and complete a guest registry form, we collect contact information
          such as your first and last name, your mailing address, your email
          address, how you heard about us and your personal phone numbers. You
          may also choose to provide optional additional information such as
          your current housing situation, your reasons for moving, where you
          currently reside, your desired housing characteristics, family status,
          age group, number of adults and children and your annual household
          income.
          <br />
          <br />
          We only use this information that you have provided on the Guest
          Registry to form a better understanding of your needs, aid you in
          finding a suitable home and to send you tailored communications about
          our developments, products and services.
          <br />
          <br />
          We also use this information on an aggregator basis to help us better
          understand our customers and to improve our products and service
          offerings.
          <br />
          <br />
          <span>Buying a Home:</span> When you complete a CPS (Contract of
          Purchase and Sale) for a residential property through Ledingham
          McAllister at any of our developments, we will collect the information
          you provided on the Agreement including the first and name and contact
          information (such as telephone numbers and mailing address) for the
          purchasers, including the property description, and purchase amount.
          We utilize this information for the purposes of facilitating the
          residential property transaction.
          <br />
          <br />
          Additionally, we will collect pertinent information to verify the
          identity of each purchaser (such as a valid government-issued piece of
          identification) as well as a personal cheque for deposit purposes and
          as required by law, REDMA and Fintrac rules, regulations and policies.
          <br />
          <br />
          <span>Warranty Registration and Requests:</span> We collect certain
          pieces of personal information, including your name, email address,
          mailing address, telephone numbers and property closing date when you
          register for a new home warranty plan in your respective and
          applicable province or when you make a warranty customer care request.
          This information will be used to complete the warranty registration
          and to fulfill your warranty request.
          <br />
          <br />
          <span>Website Registration:</span> In order to obtain access to
          information regarding products and services on any password-protected
          areas of our websites, we may request certain personal information
          such as your name, telephone number, mailing address, email address
          and the corresponding password that you have selected. We use this
          information to provide and administer your online account or service
          your request.
          <br />
          <br />
          <span>Marketing Communications:</span> When you complete and submit a
          guest registration form, or otherwise sign-up to receive information
          regarding our developments or related products and services, we
          collect your contact information such as your name, phone numbers,
          mailing addresses and email address. We will use this information to
          send you communications based on your expressed interests by mail,
          email or telephone. You may opt-out of receiving all future marketing
          and promotional communications at any time by clicking on the
          unsubscribe link included in our email communications, or by
          contacting us with the credentials noted at the bottom of this Privacy
          Policy.
          <br />
          <br />
          <span>Customer Service:</span> Any time that you contact us with a
          comment, complaint or a question, you may be asked for information to
          help us identify yourself (such as your name, address, and your
          telephone number) along with additional information we may need to
          help us promptly answer your question or respond to your comment or
          complaint (e.g: your lot number, warranty enrolment number,
          development, community, etc.) We may retain this information to assist
          you in the future and to improve our customer service and product and
          service offerings. We may additionally use personal information to
          establish and manage our relationship with you and provide quality
          customer service.
        </p>
        <h3>Disclosure and Sharing of Your Personal Information</h3>
        <p>
          We will not under any circumstances disclose, rent, trade, sell or
          otherwise transfer your personal information without your consent,
          except as otherwise outlined herein.
          <br />
          <br />
          <span>Service Providers:</span> Your personal information may be
          transferred (or otherwise made available) to our designated third
          parties or affiliates who provide services on our behalf. As an
          example, we may utilize a service provider for the maintenance of our
          website, including hosting an information form, providing additional
          services related to our site, sending electronic mail or other
          functions related to our business or services provided.
          <br />
          <br />
          Our service providers are given only the information they need to
          perform designated functions, and are not authorized under any
          circumstances to disclose personal information for their own marketing
          purposes.
          <br />
          <br />
          Your personal information may be maintained and processed by us, our
          affiliations and other third-party service providers in Canada, the US
          or other foreign jurisdictions. In the event that personal information
          is transferred to other foreign jurisdictions, it will be subject to
          the laws of that country and may be disclosed to or accessed by their
          respective courts of law (or related parties), local law enforcement
          and governmental authorities in accordance to their laws.
          <br />
          <br />
          <span>Legal/Compliance:</span> Ledingham McAllister, its affiliates,
          sister companies and its Canadian or foreign service providers may
          provide your personal information in response to a search warrant or
          other legally valid order or inquiry to another organization for the
          purposes of investigating any breaches of an agreement, violation of a
          law or detecting, preventing or suppressing fraud, or as otherwise
          permitted or required by applicable Canadian or other foreign laws.
          Your personal information may also be disclosed where necessary for
          the establishment, exercise or defense of legal claims and to prevent
          or investigate loss or harm to persons or property.
          <br />
          <br />
          <span>Sale of Business:</span> Personal information may be provided to
          third parties in connection with a prospective or completed business
          transaction, including a sale or merger (including transfers made as
          part of bankruptcy proceedings or insolvency) involving all or a part
          of Ledingham McAllister or as a part of a corporate reorganization or
          stock sale or additional or other changes in corporate control.
        </p>
        <h3>
          Internet-Based Advertising, Information About our Website and Cookies
        </h3>
        <h4>OVERVIEW</h4>
        <p>
          We and/or our service providers collect IP (Internet protocol)
          addresses from all visitors to the website and other related
          information such as browser type, operating system, page requests and
          an average time spent visiting our website.
          <br />
          <br />
          This information is only used to enable us to understand our site’s
          activity and to improve and monitor the website.
        </p>
        <h4>TRACKING TAGS, WEB BEACONS AND COOKIES</h4>
        <p>
          Our site uses a technology called ’web beacons’ and ‘tracking tags’.
          These systems allow us to understand which pages you frequent on our
          site. Tracer tags like these are utilized to help us learn how we can
          tailor and optimize our website for yourself and other site visitors.
          <br />
          <br />
          Our website utilizes a technology known as ‘cookies.’ Cookies are
          essentially pieces of data that sit on your hard drive so we can
          recognize you again when you return to our site.
          <br />
          <br />
          We utilize cookies on the sections of our site in which you are
          prompted to log in or have boxes which are customizable. In the event
          that you have registered on our site, these cookies will allow us to
          identify who you are and which browser you are using and will allow us
          to recognize that you have previously visited the site and it is may
          be necessary to access your account information (which is
          automatically stored on our computers) in an effort to personalize
          services and deliver products.
          <br />
          <br />
          Cookies provide us and our service providers with information
          pertaining to your utilization of our site (e.g: which sections of the
          site you have frequented) that will then be used to personalize our
          site in accordance with your designated interests and preferences.
          <br />
          <br />
          Our site is not designed to respond to ‘do not track’ signals that are
          received from browsers.
        </p>
        <h4>INTERNET-BASED ADVERTISING</h4>
        <p>
          You may find our advertisements on other websites through the usage of
          our third parties, such as Facebook. These companies may additionally
          use web beacons, tracer tags, cookies to report understand certain
          information surrounding your visits to our site and other sites you
          visit and/or interact to ads, in order to measure the effectiveness of
          our marketing campaigns and to deliver the appropriate ads which are
          relevant to you on and off our website.
          <br />
          <br />
          If you would like to learn additional information about the collection
          and use of information by third parties for behavioural purpose.
          Additionally, if you would like to opt-out of certain third-party
          cookies or selection what online advertisements you choose to receive,
          you may do so by visiting the Digital Advertising Alliance of Canada
          website.
          <br />
          <br />
          If you would like opt-out of internet-based ads from Google, you may
          do so here.
        </p>
        <h4>ANALYTICS</h4>
        <p>
          We may utilize services from a third party such as Google Analytics to
          aid us in obtaining and analyzing information about the sections of
          our site which you frequent. These third parties may use technologies
          as noted above including: web beacons, web server logs and cookies.
          This information will be used to improve and evaluate your customer
          experience and the simplicity and convenience of our website, in
          addition to aiding us in understanding the specific ways in which you
          use our site based on your website visits. If you would like further
          information regarding how Google uses data when on a patterns’ sites
          or applications, you may do so here.
        </p>
        <h4>GOOGLE ANALYTICS</h4>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          <br />
          <br />
          You can opt-out of having made your activity on the service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity.  <br />
          <br />
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web
          page: https://policies.google.com/privacy?hl=en
        </p>
        <h4>FACEBOOK ADVERTISEMENTS</h4>
        <p>
          Facebook offers advertising that targets users based on designed
          audiences and specific objectives. Facebook collects this data based
          on provided and approved user parameters.
          <br />
          <br />
          Alternatively, ads may target you based off of data from your browser
          through the utilization of algorithms. Facebook may use the collected
          data to contextualize and personalize the ads of its own advertising
          network.
          <br />
          <br />
          You can opt-out of ads by going to your Facebook profile, selecting
          settings, ad, and then making modification in the Ad settings (Ads
          based on data from partners) by selecting ‘Not Allowed.’
        </p>
        <h4>SOCIAL MEDIA</h4>
        <p>
          This site may provide you with the opportunity to engage with our
          content on or through third-party social networking sites,
          applications or plug-ins. When you engage with our content on or
          through third-party operated social networking websites, applications
          or plug-ins, you may allow us to have access to certain information
          that is associated with your social media accounts (Username, email,
          name) to deliver the content or as a part of the operation of the
          website, application or plug-in. When you provide information from
          your social media account, we may utilize this information to
          personalize your experience on our site and on the third-party social
          networking websites, applications or plug-ins and to provide you with
          other services or products you may request.
        </p>
        <h4>THIRD PARTY LINKS</h4>
        <p>
          Our site may contain links to other sites that are not owned or
          operated by us, this includes social media websites. The links to our
          website may be featured on third party websites on which we advertise
          on. Except as you provided herein, we will not under any circumstances
          provide any of your personal information to these third parties
          without your explicit consent. These links are provided to third party
          websites as a convenience to you as the site user. Links are not
          intended to act as an endorsement of or a referral to the linked
          sites. These linked sites have independent and separate privacy
          statements, terms of use and notices which we advise you to read very
          carefully. We do not have any control over these websites, and as
          such, have no liability or responsible for the manner in which these
          organizations that operate these linked websites may use, disclose,
          collect, secure or otherwise treat your personal information.
        </p>
        <h3>Retention and Safeguards</h3>
        <p>
          We have applied reasonable administrative, technical and physical
          measures in an effort to protect the personal information in our
          custody and control against loss, theft and unauthorized access
          including the usage, modification and disclosure of information. We
          restrict access to your personal information on a need to know basis
          to employees and authorized service providers who need access in order
          to fulfill their job requirements.
          <br />
          <br />
          Your online access to your personal information may be protected with
          a password that you have selected. We strongly discourage you from
          disclosing or sharing your password with anyone. We will never prompt
          you for your password via any unsolicited communication (such as phone
          calls, email, phone calls, or social media messaging systems).
          <br />
          <br />
          Our personal information retention processes are meant to retain
          personal information of our customers for no longer than necessary for
          the purposes stated above or to otherwise adhere to legal parameters.
        </p>
        <h4>ACESS TO YOUR PERSONAL INFORMATION</h4>
        <p>
          You retain the right to access, update and correct inaccuracies in
          your personal information that we have in our custody and control.
          This is subject to personal exceptions as prescribed by the law.
          <br />
          <br />
          You may request access to update, modify and correct inaccuracies in
          personal information that have in our possession or control by
          emailing, writing us, or calling us through the contact information as
          noted below.
          <br />
          <br />
          We may in turn request certain information for verification purposes
          in order to properly identify you as the appropriate person seeking
          access to their personal information records.
        </p>
        <h4>CHANGES TO THE PRIVACY POLICY</h4>
        <p>
          This privacy policy may be updated periodically to reflect changes to
          our personal information practices in accordance with the law. We will
          post the updated Privacy Policy on our website; your personal
          information will always be treated in accordance to what is noted
          within the Privacy Policy in place at the time your personal
          information was collected, unless you otherwise consent.
        </p>
        <h4>CONTACT US</h4>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
          <br />
          <br />
          By email:{' '}
          <a href="mailto:admin@rivierabyledmac.com">
            admin@rivierabyledmac.com
          </a>
          <br />
          By visiting this page on our website:{' '}
          <a
            href="https://rivierabyledmac.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://rivierabyledmac.com/
          </a>
          <br />
          By phone number: <a href="tel:778-658-0288">778-658-0288</a>
          <br />
        </p>
      </>
    ),
  },
  notFound: {
    title: (
      <>
        Sorry! <br />
        We Apologize
      </>
    ),
    strapline: '404 Not Found',
    body: (
      <>
        Looks like something went wrong here. <br />
        The page you are looking for does not exist.
      </>
    ),
    button: 'Back to Homepage',
  },
  thankYou: {
    title: 'Thank You',
    body: (
      <>
        Thank you for registering for Riviera by Ledingham McAllister. <br />
        Your submission has been received and a representative will be in touch
        with you soon.
      </>
    ),
    button: 'Back to Homepage',
  },
};
